import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {SectionHeading, Subheading as SubheadingBase} from "components/styles/Headings.js";
import {PrimaryButton as PrimaryButtonBase} from "components/styles/Buttons.js";
import {ReactComponent as MoneyIcon} from "feather-icons/dist/icons/dollar-sign.svg";

const Container = tw.div`relative`;
const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-8 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Features = tw.div`mx-auto md:mx-0 flex flex-col lg:flex-row max-w-xs lg:max-w-none`;
const Feature = tw.div`mt-10 lg:mt-8 flex items-center md:items-start flex-col md:mr-8 last:mr-0`;

const FeatureHeadingContainer = tw.div`flex items-center`;
const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-primary-500 text-center rounded p-2 flex-shrink-0`}
  ${props => [
    props.iconRoundedFull && tw`rounded-full`,
    props.iconFilled && tw`border-0 bg-primary-500 text-gray-100`
  ]}
  svg {
    ${tw`w-5 h-5`}
  }
`;
const FeatureHeading = tw.div`ml-3 font-bold text-xl`;

const FeatureDescription = tw.div`mt-4 text-center md:text-left text-gray-600 leading-relaxed`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-12 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

const SingleColumn = tw.div`max-w-screen-lg mx-auto py-20 lg:py-24`;

export default ({
                  subheading = "Our Expertise",
                  heading = (<>Designed & Developed by <span tw="text-primary-500">Professionals.</span></>),
                  description = "Lorem ipsum dolor sit amet.",
                  primaryButtonText = "Learn More",
                  primaryButtonUrl = "https://example.com",
                  buttonRounded = true,
                  features = null,
                  iconRoundedFull = true,
                  iconFilled = true,
                  iconContainerCss = null,
                  id = null
                }) => {
  const defaultFeatures = [
    {
      Icon: MoneyIcon,
      title: "Affordable",
      description: "We promise to offer you the best rate we can - at par with the industry standard.",
      iconContainerCss: tw`bg-red-300 text-red-800`
    }
  ];

  if (!features) features = defaultFeatures;

  return (
    <Container id={id}>
      <SingleColumn>
        <Subheading>{subheading}</Subheading>
        <Heading>{heading}</Heading>
        <Description>{description}</Description>
        <Features>
          {features.map((feature, index) => (
            <Feature key={index}>
              <FeatureHeadingContainer>
                <FeatureIconContainer
                  iconFilled={iconFilled}
                  iconRoundedFull={iconRoundedFull}
                  css={feature.iconContainerCss || iconContainerCss}
                >
                  {<feature.Icon/>}
                </FeatureIconContainer>
                <FeatureHeading>{feature.title}</FeatureHeading>
              </FeatureHeadingContainer>
              <FeatureDescription>{feature.description}</FeatureDescription>
            </Feature>
          ))}
        </Features>

        <PrimaryButton buttonRounded={buttonRounded} as="a" href={primaryButtonUrl}>
          {primaryButtonText}
        </PrimaryButton>
      </SingleColumn>
    </Container>
  );
};
