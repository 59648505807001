import "tailwindcss/dist/base.css";
import React from "react";
import SaaSProductLandingPage from "pages/Home.js";

import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Footer from "./components/misc/Footer";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "./components/misc/Header";
import ContactUs from "./components/misc/ContactUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import Sponsor from "./pages/Sponsor";


export default function App() {
  return (
    <AnimationRevealPage disabled>
      <Router>
        <Header roundedHeaderButton={true}/>
        <Switch>
          <Route path="/contact">
            <ContactUs/>
          </Route>
          <Route path="/privacy">
            <PrivacyPolicy/>
          </Route>
          <Route path="/terms">
            <TermsOfService/>
          </Route>
          <Route path="/sponsor">
            <Sponsor/>
          </Route>
          <Route path="/">
            <SaaSProductLandingPage/>
          </Route>
        </Switch>
        <Footer/>
      </Router>
    </AnimationRevealPage>
  );
}
