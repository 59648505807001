import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {SectionHeading, Subheading as SubheadingBase} from "components/styles/Headings.js";
import {PrimaryButton as PrimaryButtonBase} from "components/styles/Buttons.js";
import EmailIllustrationSrc from "images/illustrations/email.svg";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-md mx-auto md:mx-0`
const Input = tw.input`mt-3 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-32`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({
                  subheading = "Contact Us",
                  heading = <>Feel free to <span tw="text-primary-500">get in touch</span>
                    <wbr/>
                    with us.</>,
                  description = "We are always excited to hear feedback from our community or possible sponsorship opportunities.",
                  submitButtonText = "Send",
                  formAction = "https://getform.io/f/fe379d9a-0ec9-475a-96d9-64504517c3dc",
                  formMethod = "post",
                  textOnLeft = true,
                }) => {

  return (
    <AnimationRevealPage>
      <Container>
        <TwoColumn>
          <ImageColumn>
            <Image imageSrc={EmailIllustrationSrc}/>
          </ImageColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <TextContent>
              {subheading && <Subheading>{subheading}</Subheading>}
              <Heading>{heading}</Heading>
              {description && <Description>{description}</Description>}
              <Form action={formAction} method={formMethod}>
              {/* {state.succeeded && <>
                <br/>
                <Description tw={"font-bold"}>Thank you for your message, we will be in touch shortly</Description>
              </>}
              {!state.succeeded && <Form onSubmit={handleSubmit}> */}
                <Input type="email" required name="email" placeholder="Email Address" tw={"mt-0"}/>
                <div tw={"mt-3 flex flex-row gap-1 max-w-md"}>
                  <Input type="text" required name="name" placeholder="Name" style={{flex: 1}}/>
                  <Input type="text" name="company" placeholder="Company" style={{flex: 1}}/>
                </div>
                <Textarea name="message" required placeholder="Your Enquiry"/>
                <SubmitButton type="submit">{submitButtonText}</SubmitButton>
              </Form>
            </TextContent>
          </TextColumn>
        </TwoColumn>
      </Container>
    </AnimationRevealPage>

  );
};
