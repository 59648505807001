import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/containers/Hero.js";
import Features from "components/containers/ThreeColWithCards.js";
import MainFeature from "components/containers/TwoColWithButton.js";
import MainFeature2 from "components/containers/TwoColWithTitle.js";
import FeatureWithSteps from "components/containers/TwoColWithSteps.js";
import OneCol from "components/containers/OneColWithBackground";
import YoutubeVideosImage from "images/screenshots/youtube-videos-2023.jpg";
import DashboardImage from "images/screenshots/dashboard-overview.jpg";
import {ReactComponent as BriefcaseIcon} from "feather-icons/dist/icons/cloud-lightning.svg";
import {ReactComponent as MoneyIcon} from "feather-icons/dist/icons/dollar-sign.svg";
import FastIconImage from "../images/icon/mui-bolt.svg";
import SimpleIconImage from "../images/icon/mui-user.svg";
import MonitorIconImage from "../images/icon/mui-monitor.svg";
import ViewsIconImage from "../images/icon/mui-eye.svg";
import WatchIconImage from "../images/icon/mui-watch.svg";
import FaceIconImage from "../images/icon/mui-face.svg";


export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero/>
      <Features
        subheading={<Subheading>YouTube</Subheading>}
        heading={"75,000+ Videos"}
        description={"We have made tens of thousands of Wikipedia articles accessible on YouTube and " +
        "produce hundreds more every day"}
        cards={[
          {imageSrc: ViewsIconImage, title: "1,365,000+ \n Views"},
          {imageSrc: WatchIconImage, title: "20,700+ \n Watch Hours"},
          {imageSrc: FaceIconImage, title: "8,700,000+ Impressions"},
          {
            imageSrc: MonitorIconImage,
            title: "24/7 Monitoring",
            description: "We monitor our systems to ensure the best user experience."
          },
          {
            imageSrc: FastIconImage,
            title: "Fast",
            description: "Our distributed system can produce a new video in a few minutes."
          },
          {
            imageSrc: SimpleIconImage,
            title: "Easy",
            description: "Users can request up to 10 videos a day for free from a linked Google account."
          }
        ]}
      />
      <MainFeature
        subheading={<Subheading>Impact</Subheading>}
        heading={<>Designed & Developed for <HighlightedText>Accessibility</HighlightedText></>}
        id={"about"}
        description={<>Every month Wikipedia has 18 billion page views while YouTube has over 140 billion videos
          watched. WikiReader not only helps people with dyslexia, alexia and vision impairment access
          Wikipedia articles but also expands reach. Enabling both young and older audiences to expand their
          horizons.</>}
        imageSrc={YoutubeVideosImage}
        imageAlt={"Youtube Videos"}
        imageBorder={true}
        imageDecoratorBlob={true}
      />
      <FeatureWithSteps
        subheading={<Subheading>Video</Subheading>}
        heading={<>Easy <HighlightedText>Videography</HighlightedText></>}
        steps={[
          {
            heading: "Generate",
            description: "Choose a video, create text to speech with our neural engine, and create visuals for each topic."
          },
          {
            heading: "Composite",
            description: "Combine audio, visual, and special effects into an engaging video."
          },
          {
            heading: "Upload",
            description: "Share the video with the YouTube API and make public to the world."
          }
        ]}
        textOnLeft={false}
        imageSrc={DashboardImage}
        imageDecoratorBlob={true}
        imageAlt={"WikiReader Frontend"}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <MainFeature2
        subheading={<Subheading>Support</Subheading>}
        heading={<>We are Funded by Ads & Sponsorship</>}
        id={"funding"}
        showDecoratorBlob={false}
        description={<>WikiReader is currently struggling to be a monetised channel on YouTube with server and
          computing costs being a key expense. However we are exploring new avenues, such as sponsorship, to enable
          further development and widen the project even without Youtube's support.</>}
        primaryButtonText={<>Discover Sponsorship</>}
        primaryButtonUrl={"/sponsor"}
        features={[
          {
            Icon: MoneyIcon,
            title: "Cost Efficient",
            description: "We produce as many videos as possible on limited compute resources.",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: BriefcaseIcon,
            title: "Low Bandwidth",
            description: "Our unique single-frame video composition techniques reduce bandwidth for us and our users.",
            iconContainerCss: tw`bg-yellow-300 text-yellow-800`
          }
        ]}
      />
      <OneCol pushDownFooter={false}/>
    </AnimationRevealPage>
  );
}
