import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import MainFeature1 from "components/containers/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TwoColContactUsWithIllustrationFullForm from "../components/misc/ContactUs";
import GrowingImage from "../images/illustrations/growing.svg";
import ComplicatedImage from "../images/illustrations/complex.jpg";


const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <MainFeature1
        subheading={<Subheading>About Sponsorship</Subheading>}
        heading="We are looking to expand our horizons."
        description={"From adding your sponsorship message to a fixed number of videos to working with your brand " +
        "to produced videos tailored for your company, we are always looking for new ways to engage our audience " +
        "with your marketing campaign."}
        imageSrc={GrowingImage}
      />
      <MainFeature1
        subheading={<Subheading>Video Generation</Subheading>}
        heading="Our system simplifies the process"
        description={"With years of experience automatically videography for social media we have the expertise " +
        "it takes to accelerate your content into this new age of digital video."}
        id={"video"}
        imageSrc={ComplicatedImage}
        textOnLeft={false}
      />
      <TwoColContactUsWithIllustrationFullForm
        heading={"Please get in touch."}
        description={""}
      />
    </AnimationRevealPage>
  );
};
